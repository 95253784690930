<template>
    <div>
        <CompTable ref="comp_table" :searchData="searchData" title="数据报表" method="post" :columns="columns" tableApi="/gateway/api/ck/OrgTrend/getTrendData" :dataBefore="onDataBefore" :processData="onProcessData" :data="params">
            <template #search="evt">
                <Select v-model="evt.search.orgLevel">
                    <Option value="3">区</Option>
                    <Option value="4">街道</Option>
                    <Option value="5">社区</Option>
                </Select>
                <DatePicker v-model="evt.search.statDate" type="date" placeholder="选择日期" style="width: 200px" />
                <ElemIndicator v-model="evt.search.selectField"></ElemIndicator>
                <Select v-model="evt.search.timeContrast" clearable>
                    <Option value="day">日对比</Option>
                    <Option value="week">周对比</Option>
                    <Option value="month">月对比</Option>
                </Select>
            </template>
            <template #operate>
                <Button type="primary" @click="onExport">导出</Button>
            </template>
        </CompTable>
    </div>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"

import ElemIndicator from "./components/ElemIndicator.vue"

export default {
    components: {
        CompTable,
        ElemIndicator,
    },

    data() {
        const data = JSON.parse(window.localStorage.getItem("DATA_REPORT_DATA") || "{}")

        const date = new Date()
        // 日期减少一天
        date.setDate(date.getDate() - 1)

        const params = {
            timeContrast: data.timeContrast,
            statDate: date,
            orgLevel: data.orgLevel || "3",
            format: "v1",
            oemCode: JSON.parse(window.sessionStorage.getItem("userInfo") || "{}").oemCode,
            selectField: data.selectField || ["userView"],
        }

        return {
            columns: [],
            searchData: params,
            params: {
                ...params,
                statDate: [this.$core.formatDate(date, "yyyy-MM-dd")],
            },
        }
    },

    created() {},

    methods: {
        onDataBefore(data) {
            window.localStorage.setItem(
                "DATA_REPORT_DATA",
                JSON.stringify({
                    orgLevel: data.orgLevel,
                    selectField: data.selectField,
                    timeContrast: data.timeContrast,
                })
            )

            return {
                ...data,
                statDate: [this.$core.formatDate(new Date(data.statDate), "yyyy-MM-dd")],
                oemCode: JSON.parse(window.sessionStorage.getItem("userInfo") || "{}").oemCode,
            }
        },

        onProcessData(data) {
            this.columns = data?.data?.headList?.map(item => {
                return {
                    title: item.name,
                    key: item.code,
                    width: 180,
                    // 排序
                    sort: true,
                }
            })

            return data?.data
        },

        /**
         * 导出
         */
        onExport() {
            this.$refs.comp_table.export()
        },
    },
}
</script>
